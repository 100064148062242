import { isEqual } from 'lodash';
import { Result } from '~/ts-utils';
import { initDebouncedPubSub } from '~commons/debounced-pub-sub';

// It abstracts away the complexity of the configuration setting via exports
// Each parameter is set separately, so here we try to provide batching for
// changes and possibility to to get parameters when they are set

export type Config = {
  namespace: string;
  resourceId: string;
};

export const createConfigurationStore = ({
  defaultNamespace,
  defaultResourceId,
  defaultWaitForResourceId,
}: {
  defaultNamespace: string;
  defaultResourceId: string;
  defaultWaitForResourceId: boolean;
}) => {
  const state = {
    waitForResourceId: defaultWaitForResourceId,
  };
  let configState:
    | { type: 'initialized'; value: Config }
    | {
        type: 'not_initialized';
        value: Config;
      } = {
    type: 'not_initialized',
    value: {
      namespace: defaultNamespace,
      resourceId: defaultResourceId,
    },
  };
  const pubSub = initDebouncedPubSub<Config>();

  return {
    setNamespace: (namespace?: string) => {
      let nextNamespace = namespace;
      if (!nextNamespace) {
        console.error(
          `Namespace cannot be empty. Falling back to default namespace (${defaultNamespace})`,
        );
        nextNamespace = defaultNamespace;
      }
      const lastConfigState = configState;
      configState.value.namespace = nextNamespace;
      if (
        (!state.waitForResourceId || configState.type === 'initialized') &&
        lastConfigState.value.namespace !== nextNamespace
      ) {
        pubSub.publish(configState.value);
      }
    },
    setResourceId: (resourceId?: string) => {
      let nextResourceId = resourceId;
      if (!nextResourceId) {
        console.error(
          `Resource ID cannot be empty. Falling back to default resource ID (${defaultResourceId})`,
        );
        nextResourceId = defaultResourceId;
      }
      const lastConfigState = configState;
      configState = {
        type: 'initialized',
        value: {
          ...configState.value,
          resourceId: nextResourceId,
        },
      };
      if (
        lastConfigState.type === 'not_initialized' ||
        !isEqual(lastConfigState.value, configState.value)
      ) {
        pubSub.publish(configState.value);
      }
    },
    setWaitForResourceId: (value: boolean) => {
      state.waitForResourceId = value;
    },
    getConfiguration: async (): Promise<Result<Config>> => {
      return configState.type !== 'initialized' && state.waitForResourceId
        ? pubSub.waitForFirstPub(() => configState.type === 'initialized')
        : { type: 'ok', value: configState.value };
    },
    getConfigState: () => ({ ...configState }),
    getState: () => ({ ...state }),
    subscribe: pubSub.subscribe,
  };
};
