import { Middleware } from 'redux';
import { AllActions } from '../store-types';
import { biEventsExecutor } from '../../bi/bi-events';
import { InstanceManager } from '~commons/instance/get-instance';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { buildBiLogger } from '~commons/bi/bi-logger';

export type BiMiddleware = ReturnType<typeof createBiMiddleware>;

export const createBiMiddleware = (
  instanceManager: InstanceManager,
  flowAPI: ControllerFlowAPI,
): Middleware => {
  const logger = buildBiLogger(instanceManager, flowAPI);
  return (store) => (next) => (action: AllActions) => {
    const state = store.getState();
    try {
      biEventsExecutor({ action, state, logger, flowAPI });
    } catch (e) {
      log(`Event ${action.type} bi logger error: ${e}`);
    }
    return next(action);
  };
};

const log = (message: string) => {
  process.env.NODE_ENV === 'development' && console.error(message);
};
